import React  from 'react';
import { Container, Button } from 'reactstrap';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import { scroller } from 'react-scroll';

import fourtyBg from '../../images/black-friday/40_bg.png';
import fourtyL1 from '../../images/black-friday/40_l1.png';
import fourtyL2 from '../../images/black-friday/40_l2.png';
import app from '../../images/black-friday/app.png';

const BlackFriday1 = (props) => {

    const bgImage = convertToBgImage(props.background) 

    const today = new Date();
    const endOfBf = new Date('2019-12-01');
    let title = props.title;

    if(today > endOfBf){
        title = 'Cyber Monday';
    }

    return (
        <BackgroundImage
            Tag="section"
            className="black-friday-1" 
            {...bgImage}
        >
            <Container className="text-center">   
                <h1>{title}</h1>
                
                <div className="fourty2">
                    <img src={fourtyBg} alt="" className="img-fluid" />
                    <img src={fourtyL1} alt="" className="img-fluid" />
                    <img src={fourtyL2} alt="" className="img-fluid" />
                </div>
    
                <h2>taka promocja tylko raz&nbsp;w&nbsp;roku</h2>
                <Button
                    color="primary"
                    onClick={() =>
                        scroller.scrollTo('buy', {
                            duration: 1000,
                            smooth: true,
                            offset: -60,
                        })
                    }
                >Kup dostęp taniej o 40%</Button>
                <img src={app} alt="" className="img-fluid phones" />
            </Container>
        </BackgroundImage>
    );
}

export default BlackFriday1;
