import React, { useState, useEffect }  from 'react';
import { Container, Row, Col, Button, Collapse, CardBody, Card  } from 'reactstrap';

import appScreen1 from '../../images/black-friday/app_screen_1.png';
import appScreen2 from '../../images/black-friday/app_screen_2.png';
import appScreen2m from '../../images/black-friday/app_screen_2_m.png';
import appScreen3 from '../../images/black-friday/app_screen_3.png';
import appScreen3m from '../../images/black-friday/app_screen_3_m.png';
import appScreen4 from '../../images/black-friday/app_screen_4.png';
import appScreen4m from '../../images/black-friday/app_screen_4_m.png';
import {USER_COUNT} from '../../constants';

import Slider from 'react-slick';
import { scroller } from 'react-scroll';


const BlackFriday5 = () => {

    const [isOpen, setIsOpen] = useState(1);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(isOpen < 4){
                setIsOpen(isOpen+1);  
            }
            else{
                setIsOpen(1);  
            }
        }, 5000);
        
        return () => clearTimeout(timer);
    });
    
    const toggle = number => {
        setIsOpen(number);    
    }

    let glowClass = `accordion${isOpen}`;

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 500,
        fade: true,
        autoplay: true,
        autoplaySpeed: 7000,
    };

    return(
        <section className="black-friday-5">
            <Container>
                <h2 className="text-center">Aplikacja</h2>
                <h3 className="text-center">Funkcje uwielbiane<br/>przez {USER_COUNT} użytkowników</h3>

                <Row className="align-items-center d-none d-md-flex">
                    <Col md="4" >
                        <div className={`${glowClass} glow`}></div>
                        <img src={appScreen1} className={isOpen === 1 ? 'd-block img-fluid' : 'd-none'} alt=""/>
                        <img src={appScreen2} className={isOpen === 2 ? 'd-block img-fluid' : 'd-none'} alt=""/>
                        <img src={appScreen3} className={isOpen === 3 ? 'd-block img-fluid' : 'd-none'} alt=""/>
                        <img src={appScreen4} className={isOpen === 4 ? 'd-block img-fluid' : 'd-none'} alt=""/>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="7">
                    
                        <Card className={isOpen === 1 ? 'is-open accordion1' : 'accordion1'}>
                            <CardBody>
                                <h4 onClick={() => toggle(1)} className="accordion1" aria-hidden="true">4 zbilansowane diety</h4>
                                <Collapse isOpen={isOpen === 1}>
                                    <p>Każdy z nas jest inny. Mamy odmienne upodobania żywieniowe, przyzwy­czajenia i każdy z nas toleruje inne grupy produ­któw. Dlatego mam dla Ciebie propozycje nie jednej, a czterech diet.</p>
                                </Collapse>
                            </CardBody>
                        </Card>

                        <Card className={isOpen === 2 ? 'is-open accordion2' : 'accordion2'}>
                            <CardBody>
                                <h4 onClick={() => toggle(2)} className="accordion2" aria-hidden="true">Funkcjonalne treningi z Anią</h4>
                                <Collapse isOpen={isOpen === 2}>
                                    <p>Pełne mocy i energii, wzmacniają i wyszczuplają, rzeźbią mięśnie całego ciała. Chcesz obudzić w sobie duszę prawdziwego wojownika? Przełącz się na Karate Cardio Military, ulubiony program Ani.</p>
                                </Collapse>            
                            </CardBody>
                        </Card>

                        <Card className={isOpen === 3 ? 'is-open accordion3' : 'accordion3'}>
                            <CardBody>
                                <h4 onClick={() => toggle(3)} className="accordion3" aria-hidden="true">Treningi dla mężczyzn</h4>
                                <Collapse isOpen={isOpen === 3}>
                                    <p>Spalają tkankę tłuszczową, wzmacniają ciało oraz kondycję i skutecznie rzeźbią mięśnie. Treningi Kuby dedykowane są panom na wszystkich poziomach zaawansowania.</p>
                                </Collapse>
                            </CardBody>
                        </Card>
                        
                        <Card className={isOpen === 4 ? 'is-open accordion4' : 'accordion4'}>
                            <CardBody>
                                <h4 onClick={() => toggle(4)} className="accordion4" aria-hidden="true">Treningi dla par</h4>
                                <Collapse isOpen={isOpen === 4}>
                                <p>Ćwiczenia w parach nastawione są na wzmocnienie całego ciała, ale także wyrzeźbienie nóg i brzucha. Wspólny trening to dobry sposób na spędzenie czasu razem i dawka wzajemnej motywacji.</p>
                                </Collapse>
                            </CardBody>
                        </Card>
                        

                    </Col>
                </Row>

                <div className="d-md-none">
                    <Slider {...settings}>
                        <div className="position-relative">
                            <div className="glow accordion1"></div>
                            <div className="img-container">
                                <img src={appScreen1} className="img-fluid" alt=""/>
                            </div>
                            <Card className="accordion1">
                                <CardBody>
                                    <h4 className="accordion1">4 zbilansowane diety</h4>
                                    <p>Każdy z nas jest inny. Mamy odmienne upodobania żywieniowe, przyzwy­czajenia i&nbsp;każdy z nas toleruje inne grupy produ­któw. Dlatego mam dla Ciebie propozycje nie jednej, a czterech diet.</p>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="position-relative">
                            <div className="glow accordion2"></div>
                            <div className="img-container">
                                <img src={appScreen2m} className="img-fluid" alt=""/>
                            </div>
                            <Card className="accordion2">
                                <CardBody>
                                    <h4 className="accordion2">Funkcjonalne treningi z&nbsp;Anią</h4>
                                    <p>Pełne mocy i energii, wzmacniają i&nbsp;wyszczuplają, rzeźbią mięśnie całego ciała. Chcesz obudzić w sobie duszę prawdziwego wojownika? Przełącz się na Karate Cardio Military, ulubiony program Ani.</p>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="position-relative">
                            <div className="glow accordion3"></div>
                            <div className="img-container">
                                <img src={appScreen3m} className="img-fluid" alt=""/>
                            </div>
                            
                            <Card className="accordion3">
                                <CardBody>
                                    <h4 className="accordion3">Treningi dla mężczyzn</h4>
                                    <p>Spalają tkankę tłuszczową, wzmacniają ciało oraz kondycję i skutecznie rzeźbią mięśnie. Treningi Kuby dedykowane są panom na wszystkich poziomach zaawansowania.</p>
                                </CardBody> 
                            </Card>
                        </div>

                        <div className="position-relative">
                            <div className="glow accordion4"></div>
                            <div className="img-container">
                                <img src={appScreen4m} className="img-fluid" alt=""/>
                            </div>

                            <Card className="accordion4">
                                <CardBody>
                                    <h4 className="accordion4">Treningi dla par</h4>
                                    <p>Ćwiczenia w parach nastawione są na wzmocnienie całego ciała, ale także wyrzeźbienie nóg i brzucha. Wspólny trening to dobry sposób na spędzenie czasu razem i&nbsp;dawka wzajemnej motywacji.</p>
                                </CardBody>
                            </Card>
                        </div>
                    </Slider>
                </div>

                <div className="text-center">
                    <Button
                        color="primary"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Kup dostęp taniej o 40%
                    </Button>
                </div>
                
            </Container>
        
        </section>
    );
}
    


export default BlackFriday5;
