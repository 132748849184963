import React  from 'react';
import { Container, Button } from 'reactstrap';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import { scroller } from 'react-scroll';

import fourty3 from '../../images/black-friday/40_3.png';

const BlackFriday7 = (props) =>{

    const bgImage = convertToBgImage(props.background) 

    return(<BackgroundImage
        Tag="section"
        className="black-friday-7" 
        {...bgImage}
    >
        <Container className="text-center">
            <h2>taka promocja tylko raz w roku</h2>
            <div className="fourty"><img src={fourty3} alt="" className="img-fluid" /></div>
            <Button
                color="primary"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >
                Kup dostęp taniej o 40%
            </Button>
        </Container>
    
    </BackgroundImage>
)};

export default BlackFriday7;
