import React,  { useLayoutEffect, useState }  from 'react';
import { Container } from 'reactstrap';

import trener1 from '../../images/black-friday/trener_1_Ania.jpg';
import trener2 from '../../images/black-friday/trener_2_Ewelina.jpg';
import trener3 from '../../images/black-friday/trener_3_Kuba.jpg';

import Slider from 'react-slick';

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    
    return size;
}

const BlackFriday4b = (props) => {
    const [width] = useWindowSize();

    const settingsDesktop = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        speed: 500,
    };

    const settingsMobile = {
        infinite: true,
        initialSlide: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        speed: 500,
        centerMode: true,
        centerPadding: '36px',
    };

    let settings = settingsMobile;

    if(width > 768){
        settings = settingsDesktop;
    }

    return(
    <section className="black-friday-4">
        <Container>
            <h2 className="text-center">Trenerzy</h2>

            <Slider {...settings}>

                <div className="text-center px-4 px-md-0">
                    <div className="box small">
                        <img src={trener2} className="img-fluid" alt="Ewelina Godlewska" />
                    </div>
                    <div className="px-4">
                        <h3>Ewelina Godlewska</h3>
                        <p>Treningi jogi oraz pilates dla kobiet.</p>
                    </div>
                </div>

                <div className="text-center px-4 px-md-0">
                    <div className="box">
                        <img src={trener1} className="img-fluid" alt="Ania Lewandowska" />
                    </div>
                    <div className="px-4">
                        <h3>Ania Lewandowska</h3>
                        <p>Treningi wyszczuplające, siłowe, wytrzymałościowe dla kobiet oraz treningi dla par.</p>
                    </div>
                </div>            

                <div className="text-center px-4 px-md-0">
                    <div className="box small">
                        <img src={trener3} className="img-fluid" alt="Jakub Głąb" />
                    </div>
                    <div className="px-4">
                        <h3>Jakub Głąb</h3>
                        <p>Treningi siłowe, wytrzymałościowe, dla mężczyzn oraz treningi dla par.</p>
                    </div>
                </div>     
            </Slider>
        </Container>
    </section>
    );
};

export default BlackFriday4b;
