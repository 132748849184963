import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import icon1 from '../../images/black-friday/icon_1_change.svg';
import icon2 from '../../images/black-friday/icon_2_training.svg';
import icon3 from '../../images/black-friday/icon_3_online_care.svg';
import icon4 from '../../images/black-friday/icon_4_special_meal.svg';
import icon5 from '../../images/black-friday/icon_5_stats.svg';
import icon6 from '../../images/black-friday/icon_6_motivation.svg';
import icon7 from '../../images/black-friday/icon_7_straining_stuff.svg';
import icon8 from '../../images/black-friday/icon_8_shopping.svg';

const BlackFriday6 = () =>(
    <section className="black-friday-6">
        <Container>
            <Row className="text-center">
                <Col xs="6" md="3">
                    <img src={icon1} className="img-fluid" alt=""/>
                    <p>Wymiana posiłków i&nbsp;produktów</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon2} className="img-fluid" alt=""/>
                    <p>Ponad 1600 dni treningowych</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon3} className="img-fluid" alt=""/>
                    <p>Opieka dietetyków online</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon4} className="img-fluid" alt=""/>
                    <p>Jadłospisy okolicznościowe</p>
                </Col>
            </Row>

            <Row className="text-center">
                <Col xs="6" md="3">
                    <img src={icon5} className="img-fluid" alt=""/>
                    <p>Pomiary i śledzenie postępów</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon6} className="img-fluid" alt=""/>
                    <p>Rady, wskazówki, motywacja</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon7} className="img-fluid" alt=""/>
                    <p>Możliwości ćwiczenia ze sprzętem lub bez</p>
                </Col>
                <Col xs="6" md="3">
                    <img src={icon8} className="img-fluid" alt=""/>
                    <p>Interaktywna lista zakupów</p>
                </Col>
            </Row>
            
        </Container>
    
    </section>
);

export default BlackFriday6;
