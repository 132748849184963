import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";

import BlackFriday1 from "../components/Black/BlackFriday1";
import BlackFriday2 from "../components/Black/BlackFriday2";
import BlackFriday3 from "../components/Black/BlackFriday3";
import BlackFriday4 from "../components/Black/BlackFriday4";
import BlackFriday5 from "../components/Black/BlackFriday5";
import BlackFriday6 from "../components/Black/BlackFriday6";
import BlackFriday7 from "../components/Black/BlackFriday7";

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import { graphql} from "gatsby";

const Page = ({ data }) => {

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:dietAndWorkout',
            'Dieta + trening',
            'Dostęp do planu diety i treningów.',
            'blue'
        ),
        new SaleProductsGroup(
            'landing:main:diet',
            'Dieta',
            'Dostęp tylko do planu diety.',
            'red'
        ),
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp tylko do treningów.',
            'black'
        ),
    ];

    return (
        <Layout page="black-friday" showMenu={true}>
            <Seo>
                <meta name="robots" content="noindex,nofollow" />
            </Seo>
            
            <BlackFriday1 background={data.background.childImageSharp.gatsbyImageData} title="Cyber Monday" />
            <BlackFriday2
                background={data.background2.childImageSharp.gatsbyImageData}
                background2={data.background3.childImageSharp.gatsbyImageData}
            />
            <BlackFriday3 />
            <BlackFriday4 />
            <BlackFriday5 />
            <BlackFriday6 />
            <BlackFriday7 background={data.background7.childImageSharp.gatsbyImageData} />

            <SaleProductsList productsGroups={productsGroups} source="homepage" />

        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        background: file(relativePath: { eq: "black-friday/header_bg.jpg" }) {
            ...fluidImage1920
        }
        background2: file(relativePath: { eq: "black-friday/yoga_bg2.jpg" }) {
            ...fluidImage1920
        }
        background3: file(relativePath: { eq: "black-friday/yoga_bg4.jpg" }) {
            ...fluidImage960
        }
        background7: file(relativePath: { eq: "black-friday/promocja_bg.jpg" }) {
            ...fluidImage1920
        }

        
    }
`;
