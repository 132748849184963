import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from "gbimage-bridge"
import { GatsbyImage } from "gatsby-plugin-image";
import { scroller } from 'react-scroll';

const BlackFriday2 = (props) =>{

    const bgImage = convertToBgImage(props.background) 

    return (<BackgroundImage
        Tag="section"
        className="black-friday-2" 
        {...bgImage}
    >
        <Container>
            <h2 className="text-center">Nowości</h2>
            <Row>
                <Col md="6">
                    <h3><span className="tag">premiera</span><br className="d-md-none" /> Yoga</h3>
                    <p className="pink">Złap lepszy kontakt ze swoim ciałem i znajdź wewnętrzny spokój.</p>
                    <p>Uczestnicz w specjalnie dobranych sesjach jogi zawierających asany wyszczuplające oraz rzeźbiące okolice brzucha. Poznaj pozycje relaksujące ciało i umysł, uczące cierpliwości oraz koncentracji przy pomocy Eweliny - certyfikowanej nauczycielki Yogi.</p>
                    <Button
                        color="primary mt-4"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Kup dostęp taniej o 40%
                    </Button>
                </Col>
            </Row>
        </Container>

        <GatsbyImage image={props.background2} className="d-md-none" />
    
    </BackgroundImage>
)};

export default BlackFriday2;
