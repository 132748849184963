import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { scroller } from 'react-scroll';

import hydration from '../../images/black-friday/nawadnianie.png';

const BlackFriday3 = () =>(
    <section className="black-friday-3">
        <Container>
            <Row className="align-items-center">
                <Col md="5" className="order-2 order-md-1">
                    <div className="glow"></div>
                    <div className="mx-5 mx-md-3">
                        <img src={hydration} className="img-fluid mt-5 mt-md-0" alt=""/>
                    </div>
                    
                    <Button
                        color="primary"
                        className="d-md-none"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Kup dostęp taniej o 40%
                    </Button>
                </Col>
                <Col md="7" className="order-1 order-md-2">
                    <h3><span className="tag">nowość</span><br className="d-md-none" /> Monitoruj nawodnienie</h3>
                    <p>Odpowiednie nawodnienie jest jednym z kluczowych elementów odpowiadających za sprawną pracę Twojego ciała i umysłu!</p>
                    <p>Teraz korzystając z najnowszej funkcji Twoje nawodnienie już zawsze będziesz mieć pewność, że dostarczasz swojemu organizmowi wystarczającą ilość wody.</p>
                    <Button
                        color="primary"
                        className="d-none d-md-block"
                        onClick={() =>
                            scroller.scrollTo('buy', {
                                duration: 1000,
                                smooth: true,
                                offset: -60,
                            })
                        }
                    >
                        Kup dostęp taniej o 40%
                    </Button>
                </Col>
        
            </Row>
            
        </Container>
    
    </section>
);

export default BlackFriday3;
